import axios from '@/common/axios'
import qs from 'qs'

export function addInLedger (data) {
  return axios({
    method: 'post',
    url: '/storage/inLedger/add',
    data: qs.stringify(data)
  })
}

export function deleteInLedger (id) {
  return axios({
    method: 'delete',
    url: '/storage/inLedger/delete/' + id
  })
}

export function updateInLedger (data) {
  return axios({
    method: 'put',
    url: '/storage/inLedger/update',
    data: qs.stringify(data)
  })
}

export function selectInLedgerInfo (id) {
  return axios({
    method: 'get',
    url: '/storage/inLedger/info/' + id
  })
}

export function selectInLedgerList (query) {
  return axios({
    method: 'get',
    url: '/storage/inLedger/list',
    params: query
  })
}
