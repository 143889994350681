<template>
  <div id="inLedger">
    <el-dialog
      :title="inLedgerFormTitle"
      width="680px"
      :visible.sync="inLedgerDialogVisible"
      :close-on-click-modal="false"
      @close="inLedgerDialogClose"
    >
      <el-form
        ref="inLedgerFormRef"
        :model="inLedgerForm"
        :rules="inLedgerFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="时间" prop="inDate">
              <el-date-picker v-model="inLedgerForm.inDate" placeholder="请选择时间" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="物料名称" prop="materialName">
              <el-input v-model="inLedgerForm.materialName" placeholder="请输入物料名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="供货单位" prop="supplier">
              <el-input v-model="inLedgerForm.supplier" placeholder="请输入供货单位" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批号" prop="batchNum">
              <el-input v-model="inLedgerForm.batchNum" placeholder="请输入批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格/装量" prop="spec">
              <el-input v-model="inLedgerForm.spec" placeholder="请输入规格/装量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位" prop="unit">
              <el-input v-model="inLedgerForm.unit" placeholder="请输入单位" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="总量" prop="total">
              <el-input v-model="inLedgerForm.total" placeholder="请输入总量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="物料编号" prop="materialNum">
              <el-input v-model="inLedgerForm.materialNum" placeholder="请输入物料编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验单号" prop="testNum">
              <el-input v-model="inLedgerForm.testNum" placeholder="请输入检验单号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验结果" prop="testResult">
              <el-input v-model="inLedgerForm.testResult" placeholder="请输入检验结果" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="采购人" prop="purchaser">
              <el-input v-model="inLedgerForm.purchaser" placeholder="请输入采购人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="inLedgerForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="inLedgerDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="inLedgerFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="物料名称">
        <el-input v-model="searchForm.materialName" placeholder="请输入物料名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="inLedgerPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column label="时间">
        <template slot-scope="scope">
          <span v-if="scope.row.inDate">{{ scope.row.inDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="materialName" label="物料名称" />
      <el-table-column prop="supplier" label="供货单位" />
      <el-table-column prop="batchNum" label="批号" />
      <el-table-column prop="spec" label="规格/装量" />
      <el-table-column prop="unit" label="单位" />
      <el-table-column prop="total" label="总量" />
      <el-table-column prop="materialNum" label="物料编号" />
      <el-table-column prop="testNum" label="检验单号" />
      <el-table-column prop="testResult" label="检验结果" />
      <el-table-column prop="purchaser" label="采购人" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="inLedgerPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addInLedger, deleteInLedger, updateInLedger, selectInLedgerInfo, selectInLedgerList } from '@/api/storage/inLedger'

export default {
  data () {
    return {
      inLedgerDialogVisible: false,
      inLedgerFormTitle: '',
      inLedgerForm: {
        id: '',
        inDate: '',
        materialName: '',
        supplier: '',
        batchNum: '',
        spec: '',
        unit: '',
        total: '',
        materialNum: '',
        testNum: '',
        testResult: '',
        purchaser: '',
        remarks: ''
      },
      inLedgerFormRules: {
        materialName: [{ required: true, message: '物料名称不能为空', trigger: ['blur', 'change']}]
      },
      inLedgerPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        materialName: ''
      }
    }
  },
  created () {
    selectInLedgerList(this.searchForm).then(res => {
      this.inLedgerPage = res
    })
  },
  methods: {
    inLedgerDialogClose () {
      this.$refs.inLedgerFormRef.resetFields()
    },
    inLedgerFormSubmit () {
      if (this.inLedgerFormTitle === '入库总账详情') {
        this.inLedgerDialogVisible = false
        return
      }
      this.$refs.inLedgerFormRef.validate(async valid => {
        if (valid) {
          if (this.inLedgerFormTitle === '新增入库总账') {
            await addInLedger(this.inLedgerForm)
          } else if (this.inLedgerFormTitle === '修改入库总账') {
            await updateInLedger(this.inLedgerForm)
          }
          this.inLedgerPage = await selectInLedgerList(this.searchForm)
          this.inLedgerDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.inLedgerFormTitle = '新增入库总账'
      this.inLedgerDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteInLedger(row.id)
        if (this.inLedgerPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.inLedgerPage = await selectInLedgerList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.inLedgerFormTitle = '修改入库总账'
      this.inLedgerDialogVisible = true
      this.selectInLedgerInfoById(row.id)
    },
    handleInfo (index, row) {
      this.inLedgerFormTitle = '入库总账详情'
      this.inLedgerDialogVisible = true
      this.selectInLedgerInfoById(row.id)
    },
    selectInLedgerInfoById (id) {
      selectInLedgerInfo(id).then(res => {
        this.inLedgerForm.id = res.id
        this.inLedgerForm.inDate = res.inDate
        this.inLedgerForm.materialName = res.materialName
        this.inLedgerForm.supplier = res.supplier
        this.inLedgerForm.batchNum = res.batchNum
        this.inLedgerForm.spec = res.spec
        this.inLedgerForm.unit = res.unit
        this.inLedgerForm.total = res.total
        this.inLedgerForm.materialNum = res.materialNum
        this.inLedgerForm.testNum = res.testNum
        this.inLedgerForm.testResult = res.testResult
        this.inLedgerForm.purchaser = res.purchaser
        this.inLedgerForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectInLedgerList(this.searchForm).then(res => {
        this.inLedgerPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectInLedgerList(this.searchForm).then(res => {
        this.inLedgerPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectInLedgerList(this.searchForm).then(res => {
        this.inLedgerPage = res
      })
    }
  }
}
</script>

<style>
</style>
